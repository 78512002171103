.pageWrap {
    display:-webkit-flex;
    -webkit-flex-flow: column;
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.terms{
    p, ul, ol{
        font-size: 12px;
    }
}


.main {
    display:-webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin: 40px 0 40px 0;

    h2 {
        color: #2092c1;
        font-family: 'OpenSans', sans-serif;
        font-size: 32px;
        font-weight: 700;
    }
}

.team {
    display:-webkit-flex;
    /*-webkit-flex: 1 0 auto;*/
    display: flex;
    /*flex: 1 0 auto;*/
    align-items: center;
    padding: 40px 0 40px 0;
    .title {
        margin-bottom: 8px;
        a{
            text-decoration: none;
        }
    }
    .subtitle{
        text-align: center;
    }
    .mainLogo {
        svg {
            width: 100%;
            height: auto;
            margin: 20px 0;
        }
    }
}

.advisors {
    display:-webkit-flex;
    display: flex;
    align-items: center;
    padding: 40px 0 40px 0;

}

.contributors {
    .subtitle {
        color: #2092c1;
    }
    .contributorsWrapper {
        img {
            height: auto;
            max-width: 100%;
        }
    }
}

.teamItemWrapper{
    text-align: center;
    padding: 16px;
    margin: 16px 0;
    .teamItemPhoto{
        margin-bottom: 16px;
        padding: 40px;
        .photoWrapper{
            margin: 0 auto;
            .photoBg{
                img {
                    max-width: 100%;
                    border-radius: 50%;
                }

                /*border: 3px solid #2092c1;*/
                /*-webkit-box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);
                -moz-box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);
                box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);*/

                border-radius: 50%;
                /*padding-top: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('https://images.squarespace-cdn.com/content/v1/5aa81fb4506fbe5eef56d916/1554713557310-4YQ1IENO8YR2NHN82NOH/ke17ZwdGBToddI8pDm48kOyctPanBqSdf7WQMpY1FsRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyD4IQ_uEhoqbBUjTJFcqKvko9JlUzuVmtjr1UPhOA5qkTLSJODyitRxw8OQt1oetw/placeholder.png');
            */
            }
        }
    }

    .description {
        .role {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .title {
            font-size: 20px;
        }
    }
}

.enhanced {
    color: #2092c1;
    font-weight: 700;
}

@media only screen and (min-width: 767px) and (max-width: 1024px){


    .teamItemWrapper{
        .teamItemPhoto {
            padding: 16px;
        }
    }
}

@media only screen and (max-width: 767px){


    .teamItemWrapper{
        padding: 16px 0;
        .teamItemPhoto {
            padding: 16px 40px;
        }
    }

    .main {
        margin: 20px 0 0 0;
    }

    .content {
        padding: 10px 0 60px 0;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        color: #000;
    }
}
