.pageWrap {
    display:-webkit-flex;
    -webkit-flex-flow: column;
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.main {
    display:-webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-bottom: 40px;
    /*.title {
        font-size: 54px;
        line-height: 60px;
    }*/
}

.content {
    display:-webkit-flex;
    -webkit-flex: 1 0 auto;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    .subtitle{
        font-size: 18px;
        margin-bottom: 60px;
    }
}

.banner {
    display:-webkit-flex;
    -webkit-flex: 0 1 auto;
    display: flex;
    flex: 0 1 auto;
    margin: 48px 0 0 0;
    padding: 3rem 0;
    color: #fff;
    background-color: #2092c1;
    p {
        color: #fff;
    }
}

.newspaper {
    .newspaperTitle{
        h2{
            font-family: 'OpenSans', sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.5px;
            color: #253139;
        }
        margin-bottom: 48px;
    }

    .imageContainer {
        display: -webkit-flex;
        -webkit-justify-content: center;
        display: flex;
        justify-content: center;

        .imageWrapperFloat {
            float: left;
            margin: 20px 32px;
            img {
                max-height: 56px;
                filter: grayscale(100%);
            }
        }

        .imageWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            img {
                filter: grayscale(100%);
                max-height: 44px;
            }
        }
    }

}



.explain_section{
    margin: 160px 0 0;
    .explain_title{
        h2{
            font-family: 'OpenSans', sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.5px;
            color: #253139;
        }
        margin-bottom: 48px;
    }
    .explain_inner{
        padding: 32px 0;
        h4{
            font-family: 'OpenSans', sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.1px;
            color: #2092c1;
        }
    }
    p{
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #3e515e;
        margin: 0;
    }
}
.main {
    margin: 40px 0 0 0;
}




@media only screen and (min-width: 768px){
    .newspaper {
        .imageContainer {
            .imageWrapperFloat {
                text-align: center;
                margin: 20px 16px;
                img {
                    max-height: 40px;
                }
            }
        }
    }
}


@media only screen and (min-width: 992px){

    .newspaper {
        .imageContainer {
            .imageWrapperFloat {
                text-align: center;
                margin: 20px 28px;
                img {
                    max-height: 48px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px){

    .newspaper {
        .imageContainer {
            .imageWrapperFloat {
                text-align: center;
                margin: 20px 36px;
                img {
                    max-height: 56px;
                }
            }
        }
    }
}


@media only screen and (max-width: 767px){

    .main {
        margin: 20px 0 0 0;
        .title {
            font-size: 28px;
            line-height: 32px;
            color: #000;
        }
    }

    .content {
        padding: 10px 0 60px 0;
        .subtitle{
            font-size: 16px;
            margin-bottom: 32px;
        }
    }

    .banner {
        padding: 40px 0;
        margin: 20px 0 0 0;
    }

    .newspaper {
        .imageContainer {
            -webkit-flex-direction: column;
            flex-direction: column;
            .imageWrapperFloat {
                float: none;
                margin: 12px 0;
                text-align: center;
                img {
                    max-height: 56px;
                }
            }
        }
    }


    .explain_section{
        margin: 80px 0 0;
        .explain_title{
            h2{
                font-size: 32px;
            }

        }
        .explain_inner{
            padding: 24px 0;
            h4{
                font-size: 24px;

            }
        }
    }
}
