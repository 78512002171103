.footer {
    background-color: #fff;
    /*display:-webkit-flex;
    -webkit-flex: 0 1 auto;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;*/

    p{
        margin:0;
    }

    .ftMain{
        padding: 24px 32px;
        display:-webkit-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;

        .ftMainItem {
            padding: 0;
            font-family: 'Karla', sans-serif;
            font-size: 14px;
            line-height: 1.43;
            color: #8b949a;
            margin: 0 0 0 16px;

            &.disclaimer {
                display: inline-flex;
                align-items: center;
                max-width: 698px;
                .logo {
                    padding: 0 8px;
                    img{
                        height: 42px;
                    }
                }
                &.disclaimerText {
                }
            }

            &.list {
                display: inline;
                ul {
                    list-style: none;
                    padding-left: 24px;
                    margin: 0;
                    li {
                        display: inline;
                        margin: 0 14px 0 0;
                        a {
                            text-decoration: none !important;
                            color: #8b949a;
                        }
                    }
                }
            }
        }
    }

    .ftBottom {
        padding: 16px 24px;
        display:-webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fcfbfb;

        .ftMainItem {
            padding: 0;
            font-family: 'Karla', sans-serif;
            font-size: 14px;
            line-height: 1.43;
            color: #8b949a;
            margin: 0 0 0 16px;
            &.copyright {
                min-width: 240px;
                font-weight: 700;
            }
        }


    }
}

@media only screen and (min-width: 1040px) and (max-width: 1440px){

    .footer {
        .ftMain {
            .ftMainItem {
                &.list {
                    ul {
                        padding-left: 0;
                        min-width: 300px;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 1024px){

    .footer {

        .ftBottom {
            padding: 16px;
            margin: 0;
            .ftMainItem {
                margin: 0 16px 0 0;
            }
        }
        .ftMain {
            -webkit-flex-direction: column;
            flex-direction: column;
            padding: 24px 16px;
            .ftMainItem {
                text-align: center;
                margin: 0;
                &.disclaimer {
                    display: block;
                    margin: 0 8px;
                    .logo {
                        padding: 0 8px 8px 8px;
                    }
                }
                &.list {
                    ul {
                        padding: 16px 0 0 0;
                        li {
                            margin: 0 12px 0 0;
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){

    .footer {
        .ftBottom {
            flex-flow: column;
            .ftMainItem {
                padding: 0;
                margin: 0 0 12px 0;
            }
        }
    }
}
