.pageWrap {
    display:-webkit-flex;
    -webkit-flex-flow: column;
    display: flex;
    flex-flow: column;
    height: 100vh;
}
.wrapper {
    padding: 80px 0;
    display:-webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex: 1 0 auto;
    background-color: #2092c1;
    //min-height: 70vh;

    .logoBig {
        img{
            height: 68px;
        }
    }

    .title{
        font-family: 'OpenSans', sans-serif;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        text-align: center;
        color: #ffffff;
    }

    h2 {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    .subtitle {
        text-align: center;
        font-size: 24px;
        color: #fff;
        line-height: 1.2;
        margin-bottom: 60px;
    }

    .communication {

        .dangerIcon {
            padding: 16px;
            svg {
             fill: #FDD007;
            }
        }
        .communicationTitle {
            margin-bottom: 24px;
            font-size: 24px;
            color: #fff;
        }
        .communicationParagraph{
            font-family: 'OpenSans', sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
        }
    }


    .boxMessage {
        padding: 24px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        background-color: rgba(255, 255, 255, 0.39);
        p:last-child {
            margin-bottom: 0;
        }

        .copyArea {
            .copyGroup {
                display: -webkit-flex;
                -webkit-justify-content: center;
                display: flex;
                justify-content: center;
                .copyInput {
                    max-width: 180px;
                    border-radius: 4px;
                    background-blend-mode: multiply;
                    background-image: linear-gradient(to bottom, rgba(9, 19, 33, 0.06), rgba(9, 19, 33, 0.06));
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
            }
            .copyMessage {
                padding: 8px;
            }
        }

        .btnDefault {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

    }

    .btnDefault {
        background-color: #2092c1;
        color: #fff;
        font-weight: 700;
    }

    @media only screen and (max-width: 1196px) {
        padding: 62px 0;
    }

    @media only screen and (max-width: 768px){
        padding: 48px 0;
        p.subtitle {
            margin-bottom: 20px;
        }
    }
}


