.pageWrap {
    display:-webkit-flex;
    -webkit-flex-flow: column;
    display: flex;
    flex-flow: column;
    height: 100vh;
}
.wrapper {
    padding: 64px 0;
    display:-webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex: 1 0 auto;
    //min-height: 70vh;

    h2 {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    .subtitle {
        text-align: center;
        font-size: 32px;
        color: #253139;
        line-height: 144%;
        margin-bottom: 60px;
    }

    .btnDefault {
        background-color: #2092c1;
        color: #fff;
        font-weight: 700;
    }

    @media only screen and (max-width: 1196px) {
        padding: 62px 0;
    }

    @media only screen and (max-width: 768px){
        padding: 0 0 48px 0;
        p.subtitle {
            margin-bottom: 20px;
        }
    }
}


