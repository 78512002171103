.box {

    width: 100%;
    height: 200px;
    max-width: 340px;
    padding: 24px;
    margin: 8px auto;
    border: 2px #2092c1 solid;
    border-radius: 12px;
    cursor: pointer;

    .boxTitle {
        color: #2092c1;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        cursor: pointer;
    }
    .boxDescription {
        font-family: 'Karla', sans-serif;
        max-width: 228px;
        font-size: 16px;
        line-height: 1.5;
    }

    &.active, &:hover, input[type=radio]:checked {
        background-color: #2092c1;
        label, p,h3 {
            color: #fff;
        }
        -webkit-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
        -moz-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
        box-shadow: 0 0 20px 1px rgba(125,125,125,1);
    }

    input[type=radio]{
        display:none;
    }
}

.boxActive{
    background-color: #1f92c1;
    .boxTitle, .boxDescription {
        color: #fff;
    }
    -webkit-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
    -moz-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
    box-shadow: 0 0 20px 1px rgba(125,125,125,1);
}

.box_alt{
    border: 2px #20ca83 solid;
    .boxTitle {
        color: #20ca83;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        cursor: pointer;
    }
    .boxDescription {
        font-family: 'Karla', sans-serif;
        max-width: 228px;
        font-size: 16px;
        line-height: 1.5;
    }

    &.active, &:hover, input[type=radio]:checked {
        background-color: #20ca83;
        label, p,h3 {
            color: #fff;
        }
        -webkit-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
        -moz-box-shadow: 0 0 20px 1px rgba(125,125,125,1);
        box-shadow: 0 0 20px 1px rgba(125,125,125,1);
    }
}

@media only screen and (max-width: 767px){
    .box {
        height: 150px;
    }

    .boxTitle {
        font-family: 'OpenSans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: -0.1px;
        text-align: center;
    }
}
