body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button, a,input[type=radio],input[type=checkbox] {
  cursor: pointer;
}

.answere-txt{
  cursor: pointer;
}

.btns-container{
  margin: 32px 0;
  display: grid;
  grid-gap: 16px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.btns-container .back{
  text-align: left;
}

.btns-container .next{
  text-align: right;
}

.answers-list {
  /*min-height: 200px;*/
}

.submit-answers{
  margin-top: 32px;
}


.header{
  width: 100%;
  background-color: #00a9e8;
  padding: 32px 8px;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr auto;
}

.header .logo{
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
}

.slogan.slogan-box {
  margin-top:16px;
}

.home img {
  margin-top:32px;
  width: 300px;
}

.slogan .slogan-title {
  font-size: 2rem;
  font-weight: bold;
  color: #555;
}

.slogan.slogan-message {
  font-size: 1.2rem;
  padding: 1rem;
  background: #00a9e82e;
  border-radius: 8px;
  color: #555;
}

.intro{
  margin-top: 32px;
  color: #555;
}

.intro .start {
  margin: 16px 0;
}

.start.start-message {
  font-size: 2rem;
}

.logo img{
  height: 36px;
}


/* Buttons */

.blue {
  background: #00a9e8;
  border-color: #00a9e8;
}


@media only screen and (max-width: 600px) {

  .intro{
    margin-top: 12px;
    color: #555;
  }

  .home img {
    margin-top:16px;
    width: 180px;
  }

  .slogan.slogan-title {
    font-size: 1.6rem;
  }
  .start.start-message {
    font-size: 1.6rem;
  }
}

