.wrapper {
    margin: 60px 0;
    min-height: 70vh;
    .formContainer{
        max-width: 700px;
        margin: 0 auto;
    }
    h1 {
        text-align: center;
        font-weight: 700;
        font-size: 2.4rem;
        color: #253139;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 1.2rem;
        margin-bottom: 24px;
        font-weight: 700;
    }
    p.subtitle {
        text-align: center;
    }

    .result {
        padding: 20px 80px;
        border-radius: 4px;
        border: solid 1px #2092c1;
        background-color: rgba(12, 201, 255, 0.17);
        margin: 10px 0;
        p {
            margin-bottom: 0;
        }
    }

    label {
        font-size: 16px;
        line-height: 155%;
        margin-right: 16px;
    }

    input {
        background-color: #edeced;
        margin-right: 8px;
    }

    textarea {
        background-color: #edeced;
    }

    .btnDefault {
        background-color: #2092c1;
        color: #fff;
        font-weight: 700;
        width: 100%;
        &:disabled {
            cursor: not-allowed;
        }
    }

    .notesField {
        height: 140px;
    }

    .alertMessage {
        text-align: center;
        color: #ff0034;
        font-weight: 700;
        margin-bottom: 32px;
    }

}

@media only screen and (max-width: 767px){

    .wrapper {
        .result {
            padding: 16px;
            margin: 10px 0;
        }
    }
}
