.wrapper {
    margin: 60px 0;
    min-height: 70vh;

    .offer, .signup {
        padding: 48px 0;
    }

    .formContainer{
        max-width: 1024px;
        padding: 0 24px;
        margin: 0 auto;

        .doctorSection {
            margin-bottom: 56px;
        }
        .featuresSection {
            .featuresTitle{
                margin-bottom: 64px;
                font-family: 'OpenSans', sans-serif;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.33;
                text-align: center;
                color: #2092c1;
            }
        }
    }
    h1 {
        text-align: center;
        font-weight: 700;
        font-size: 2.4rem;
        color: #253139;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 1.2rem;
        margin-bottom: 24px;
        font-weight: 700;
    }

    .subtitle {
        text-align: center;
        font-size: 14px;
    }

    .spamAlert {
        text-align: center;
        font-size: 14px;
        color: #ff0034;
    }

    .requested {
        text-align: left;
        font-size: 14px;
    }

    .result {
        padding: 20px 80px;
        border-radius: 4px;
        border: solid 1px #2092c1;
        background-color: rgba(12, 201, 255, 0.17);
        margin: 40px 0;
        p {
            margin-bottom: 0;
        }
    }

    label {
        font-size: 16px;
        line-height: 155%;
        margin-right: 16px;
    }

    input, select {
        background-color: #edeced;
        margin-right: 8px;
    }

    textarea {
        background-color: #edeced;
    }

    .btnDefault {
        background-color: #2092c1;
        color: #fff;
        font-weight: 700;
        width: 100%;
        &:disabled {
            cursor: not-allowed;
        }
    }

    .notesField {
        height: 140px;
    }

    .alertMessage {
        text-align: center;
        color: #ff0034;
        font-weight: 700;
        margin-bottom: 32px;
    }


    .iconbox {
        .icon {
            float:left;
            margin: 28px;
            svg{
                max-width: 94px;
            }
        }
        .text {
            overflow: hidden;
            h4 {
                font-family: 'OpenSans', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.56;
                color: #3e515e;
            }
            p{
                font-family: 'Karla', sans-serif;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.1px;
                color: #3e515e;
            }
        }
    }

}

@media only screen and (max-width: 992px){

    .wrapper {

        .formContainer{
            .featuresSection {
                .featuresTitle{
                    margin-bottom: 48px;
                }
            }
        }
        .result {
            padding: 16px;
            margin: 20px 0;
        }
        .iconbox {
            text-align: center;
            .icon {
                width: 100%;
                margin: 28px 0;
                float: none;
            }
            .text {
                overflow: visible;
            }
        }
    }
}
