.pageWrap {
    display:-webkit-flex;
    -webkit-flex-flow: column;
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.main {
    display:-webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin: 40px 0 40px 0;

    h2 {
        color: #2092c1;
        font-family: 'OpenSans', sans-serif;
        font-size: 32px;
        font-weight: 700;
    }
}

.team {
    display:-webkit-flex;
    display: flex;
    align-items: center;
    padding: 40px 0;
    .title {
        margin-bottom: 8px;
        a{
            text-decoration: none;
        }
    }
    .subtitle{
        text-align: center;
    }
    .mainLogo {
        svg {
            width: 100%;
            height: auto;
            margin: 20px 0;
        }
    }
}

.advisors {
    display:-webkit-flex;
    display: flex;
    align-items: center;
    padding: 40px 0;
}

.contributors {
    padding: 40px 0;

    .subtitle {
        color: #3e515e;
    }
    .contributorsWrapper {
        img {
            height: auto;
            max-width: 100%;
        }
    }
}

.teamItemWrapper{
    text-align: center;
    padding: 16px;
    //margin: 16px 0;
    .teamItemPhoto{
        margin-bottom: 16px;
        padding: 40px;
        .photoWrapper{
            margin: 0 auto;
            .photoBg{
                img {
                    width: 100%;
                    border-radius: 50%;
                }
                border-radius: 50%;
            }
        }
    }

    .description {
        .role {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .title {
            margin-bottom: 8px;
            font-size: 20px;
        }
    }
}

.enhanced {
    color: #2092c1;
    font-weight: 700;
}

// Large devices (desktops, less than 1200px)
@media only screen and (max-width: 1199.98px) {
    .teamItemWrapper{
        .teamItemPhoto {
            padding: 16px;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media only screen and (max-width: 991.98px) {

}

// Small devices (landscape phones, less than 768px)
@media only screen and (max-width: 767.98px) {
    .team {
        .mainLogo {
            svg {
                padding: 16px 32px;
            }
        }
    }

    .teamItemWrapper{
        padding: 16px 0;
        .teamItemPhoto {
            padding: 16px 128px;
        }
    }

    .main {
        margin: 20px 0 0 0;
    }

    .content {
        padding: 10px 0 60px 0;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
        color: #000;
    }
}

@media only screen and (max-width: 575.98px) {
    .teamItemWrapper{
        .teamItemPhoto {
            padding: 16px 80px;
        }
    }
}
