a{
  text-decoration: none!important;
  cursor: pointer;
  .card{
	box-sizing: border-box;
	background-color: #fff;
	width: 100%;
	border-radius: 12px;
	  border: 2px solid transparent;
	box-shadow: 0 2px 10px 0 rgba(62, 81, 94, 0.35);
	padding: 32px;
	text-align: center;
	max-width: 480px;
	margin: 32px 0;
	min-height: 359px;
	/* margin: 0 auto;*/
	img{
	  max-width: 92px;
	  min-height: 101px;
	  margin-bottom: 24px;
	}
	.title{
	  font-family: 'OpenSans', sans-serif;
	  font-size: 32px;
	  font-weight: bold;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.19;
	  letter-spacing: -0.4px;
	  text-align: center;
	  color: #2092c1;
	}
	.message{
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.43;
	  letter-spacing: normal;
	  text-align: center;
	  color: #3e515e;
	  padding: 8px 0 24px;
	  margin: 0;
	}
	.cta{
	  font-size: 18px;
	  font-weight: bold;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.56;
	  letter-spacing: normal;
	  text-align: center;
	  color: #0cc9ff;
	  text-decoration: underline!important;
	}
	&:hover{
	  box-sizing: border-box;
	  border: 2px solid #2092c1;
	  /*transition: ease-in 300ms;*/
	}
  }
}

@media only screen and (max-width: 767px) {
  a{
	.card{
	  margin: 16px auto;
	  min-height: unset;
	  img{
		max-width: 64px;
		min-height: 71px;
		margin: 0;
	  }
	  .title{
		font-size: 24px;
	  }
	  .message{
		padding: 16px 0 12px;
		text-align: left;
	  }
	  .cta{
		text-align: left;
		display: block;
	  }
	}
  }
}
