@import url(https://fonts.googleapis.com/css?family=Karla:400,700|Open+Sans:400,600,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button, a,input[type=radio],input[type=checkbox] {
  cursor: pointer;
}

.answere-txt{
  cursor: pointer;
}

.btns-container{
  margin: 32px 0;
  display: grid;
  grid-gap: 16px;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: 1fr 1fr;
}

.btns-container .back{
  text-align: left;
}

.btns-container .next{
  text-align: right;
}

.answers-list {
  /*min-height: 200px;*/
}

.submit-answers{
  margin-top: 32px;
}


.header{
  width: 100%;
  background-color: #00a9e8;
  padding: 32px 8px;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr auto;
}

.header .logo{
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
}

.slogan.slogan-box {
  margin-top:16px;
}

.home img {
  margin-top:32px;
  width: 300px;
}

.slogan .slogan-title {
  font-size: 2rem;
  font-weight: bold;
  color: #555;
}

.slogan.slogan-message {
  font-size: 1.2rem;
  padding: 1rem;
  background: #00a9e82e;
  border-radius: 8px;
  color: #555;
}

.intro{
  margin-top: 32px;
  color: #555;
}

.intro .start {
  margin: 16px 0;
}

.start.start-message {
  font-size: 2rem;
}

.logo img{
  height: 36px;
}


/* Buttons */

.blue {
  background: #00a9e8;
  border-color: #00a9e8;
}


@media only screen and (max-width: 600px) {

  .intro{
    margin-top: 12px;
    color: #555;
  }

  .home img {
    margin-top:16px;
    width: 180px;
  }

  .slogan.slogan-title {
    font-size: 1.6rem;
  }
  .start.start-message {
    font-size: 1.6rem;
  }
}


* {
  font-family: 'Karla', sans-serif; }

html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

.header {
  width: 100%;
  background-color: #fff;
  padding: 32px 8px;
  display: -webkit-flex;
  -webkit-flex: 0 1 auto;
  display: flex;
  flex: 0 1 auto;
  -webkit-justify-content: center;
          justify-content: center; }

/*.footer{
    background-color: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    p{
        margin:0;
    }
}*/
.survey-section {
  padding: 60px 0 60px 0; }

.survey-container {
  max-width: 860px; }

.survey-answers h3 {
  font-size: 32px;
  color: #3e515e;
  font-family: 'Karla', sans-serif;
  text-align: left;
  margin-bottom: 16px; }

.survey-answer {
  padding: 8px 16px;
  border: 1px solid #2092c1cc;
  border-radius: 4px;
  /*min-width: 500px;*/
  width: 100%; }
  .survey-answer label {
    opacity: 0.96;
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    line-height: 1.56;
    color: #253139; }

.small-text {
  opacity: 0.92;
  font-size: 14px;
  line-height: 1.43;
  color: #2092c1;
  font-weight: 400; }

/* Survey Checkmark */
.customCheckmark {
  margin-bottom: 8px; }

.customCheckmark input[type=checkbox] {
  display: none; }

.customCheckmark {
  font-size: 16px;
  line-height: 20px; }

.customCheckmark label {
  cursor: pointer; }

.customCheckmark label:before {
  display: inline-block;
  width: 22px;
  margin-right: 12px;
  border-radius: 20px;
  border: 1px solid #2092c1cc;
  content: "\A0";
  text-align: center;
  background: #eee; }

.customCheckmark.multipleCheck label:before {
  border-radius: 4px; }

.customCheckmark label:hover::before {
  /*content: "\2713";
    background: #2092c1;
    color: #fff;*/ }

@media only screen and (min-width: 768px) {
  .customCheckmark label:hover {
    /*border: solid 1px #2092c1;*/
    background-color: rgba(12, 201, 255, 0.26); } }

/* On check */
.customCheckmark input:checked ~ label:before {
  content: "\2713";
  background: #2092c1;
  color: #fff; }

.customCheckmark input:checked ~ label {
  border: solid 1px #2092c1;
  background-color: rgba(12, 201, 255, 0.26); }

/* Survey Checkbox */
.customCheckbox {
  margin-bottom: 8px; }

.customCheckbox input[type=radio] {
  display: none; }

.customCheckbox {
  font-size: 16px;
  line-height: 20px; }

.customCheckbox label {
  cursor: pointer; }

.customCheckbox label:before {
  display: inline-block;
  width: 22px;
  margin-right: 12px;
  border-radius: 20px;
  border: 1px solid #2092c1;
  content: "\A0";
  text-align: center;
  background: #eee; }

.customCheckbox label:hover::before {
  /*content: "\2713";
    background: #2092c1;
    color: #fff;*/ }

.customCheckbox label:hover {
  /*border: solid 1px #2092c1;*/
  background-color: rgba(12, 201, 255, 0.26); }

/* On check */
.customCheckbox input:checked ~ label:before {
  content: "\2713";
  background: #2092c1;
  color: #fff; }

.customCheckbox input:checked ~ label {
  border: solid 1px #2092c1;
  background-color: rgba(12, 201, 255, 0.26); }

.statics ul li, .statics ol li {
  font-weight: 400;
  font-size: 18px;
  color: #3e515e; }

form {
  /* On check */
  /* Form Checkbox */
  /* On check */
  /* Placeholder */ }
  form .formCustomCheckmark {
    margin-bottom: 8px; }
    form .formCustomCheckmark input[type=checkbox][disabled] + label {
      cursor: not-allowed; }
      form .formCustomCheckmark input[type=checkbox][disabled] + label a {
        cursor: not-allowed;
        color: #8395a6; }
      form .formCustomCheckmark input[type=checkbox][disabled] + label::before {
        border-color: #1f92c157;
        opacity: 0.6; }
  form .formCustomCheckmark input[type=checkbox] {
    display: none; }
  form .formCustomCheckmark {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    position: relative; }
  form .formCustomCheckmark label {
    cursor: pointer;
    position: relative;
    padding: 0 0 0 36px; }
  form .formCustomCheckmark label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 26px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #1f92c1;
    content: "\A0";
    text-align: center;
    background: #eee; }
  form .formCustomCheckmark label:hover::before {
    background: #aaa; }
  form .formCustomCheckmark input:checked ~ label:before {
    content: "\2713";
    background: #1f92c1;
    color: #fff; }
  form .formCustomCheckbox {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 20px; }
  form .formCustomCheckbox input[type=radio] {
    display: none; }
  form .formCustomCheckbox label {
    cursor: pointer;
    margin-left: 16px;
    padding: 8px;
    min-width: 141px;
    border-radius: 4px;
    border: solid 1px #2092c1; }
  form .formCustomCheckbox label:before {
    display: inline-block;
    width: 26px;
    margin-right: 12px;
    border-radius: 20px;
    border: 1px solid #2092c1;
    content: "\A0";
    text-align: center;
    background: #eee; }
  form .formCustomCheckbox label:hover::before {
    content: "\2713";
    background: #2092c1;
    color: #fff; }
  form .formCustomCheckbox label:hover {
    border: solid 1px #2092c1; }
  form .formCustomCheckbox input:checked ~ label:before {
    content: "\2713";
    background: #2092c1;
    color: #fff; }
  form .formCustomCheckbox input:checked ~ label {
    border: solid 1px #2092c1;
    background-color: rgba(12, 201, 255, 0.26); }
  form ::-webkit-input-placeholder {
    /* Edge */
    font-size: 12px; }
  form ::-ms-input-placeholder {
    font-size: 12px; }
  form ::placeholder {
    font-size: 12px; }
  form .invalid-input {
    color: #ff0034;
    font-size: 12px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Karla', sans-serif;
  color: #253139; }

h1 {
  font-family: 'OpenSans', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: -1px;
  text-align: center;
  color: #253139; }

h2 {
  font-size: 40px;
  line-height: 48px; }

h3 {
  font-family: 'OpenSans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.19;
  letter-spacing: -0.4px;
  /*text-align: center;*/ }

.label {
  font-family: 'OpenSans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: #ffffff; }

p {
  font-family: 'Karla', sans-serif;
  font-size: 18px;
  line-height: 1.56;
  color: #3e515e;
  font-weight: 400; }

a {
  text-decoration: underline !important;
  color: #212529; }
  a:hover {
    color: #212529; }

.btn-default {
  background-color: #2092c1;
  color: #fff;
  font-weight: 700;
  width: 120px;
  font-family: 'OpenSans', sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(62, 81, 94, 0.32); }
  .btn-default:hover {
    color: #fff; }
  .btn-default:hover:not(:disabled) {
    background-color: #2083b1;
    color: #fff;
    -webkit-transition: ease-in 0.2s;
    transition: ease-in 0.2s; }

.btns-container {
  margin: 32px 0;
  display: grid;
  grid-gap: 16px;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: 1fr 1fr; }

.btns-container .back {
  text-align: left;
  font-size: 16px;
  font-family: 'Karla', sans-serif;
  color: #3e515e; }

.btns-container .next {
  text-align: right; }
  .btns-container .next .btn {
    width: 100px; }

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 32px; }
  /* Todo: Temporary - Waiting for design */
  .survey-answer {
    position: relative;
    padding: 16px 16px 16px 48px;
    border: 1px solid #2092c1;
    border-radius: 4px;
    min-width: 0;
    min-width: initial; }
  .customCheckmark label:before,
  .customCheckbox label:before {
    position: absolute;
    top: 14px;
    left: 14px; }
  .btn-default {
    width: 100% !important; }
  .btns-container {
    margin: 16px 0; }
  .btns-container .next {
    grid-column: 1 / -1;
    grid-row: 1; }
  .btns-container .back {
    grid-column: 1 / -1;
    text-align: center; }
  .survey-section {
    padding: 40px 0; }
  .customCheckbox label:hover::before {
    content: "\A0";
    background: #eee; }
  .customCheckbox label:hover {
    background: none; } }

.Header_header__AbTdb {
  width: 100%;
  background-color: #fff;
  padding: 16px 32px;
  display: -webkit-flex;
  -webkit-flex: 0 1 auto;
  display: flex;
  flex: 0 1 auto;
  -webkit-align-items: center;
          align-items: center;
  /*justify-content: space-around;
    flex-wrap: wrap;*/
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .Header_header__AbTdb .Header_logo__26OqQ img {
    height: 36px; }
  .Header_header__AbTdb .Header_mainNav__3Y8-W .Header_mainNavList__1ePRm {
    padding: 0;
    margin: 0;
    text-align: center; }
    .Header_header__AbTdb .Header_mainNav__3Y8-W .Header_mainNavList__1ePRm li {
      display: inline;
      list-style: none; }
      .Header_header__AbTdb .Header_mainNav__3Y8-W .Header_mainNavList__1ePRm li a {
        display: inline-block;
        padding: 0;
        color: #2092c1;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: underline !important; }

@media only screen and (max-width: 1280px) {
  .Header_header__AbTdb {
    -webkit-justify-content: center;
    justify-content: center;
    padding: 16px; }
    .Header_header__AbTdb .Header_mainNav__3Y8-W {
      display: none; } }

.loader-container{
    width: 100%;
    height: 100vh;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
}
.lds-heart div {
    top: 23px;
    left: 19px;
    position: absolute;
    width: 26px;
    height: 26px;
    background: #00a9e8;
    -webkit-animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    background: #00a9e8;
}
.lds-heart div:before {
    left: -17px;
    border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
    top: -17px;
    border-radius: 50% 50% 0 0;
}
@-webkit-keyframes lds-heart {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
    5% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    39% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    45% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    60% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}
@keyframes lds-heart {
    0% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
    5% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    39% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    45% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    60% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #00a9e8;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00a9e8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.Footer_footer__26U7T {
  background-color: #fff;
  /*display:-webkit-flex;
    -webkit-flex: 0 1 auto;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;*/ }
  .Footer_footer__26U7T p {
    margin: 0; }
  .Footer_footer__26U7T .Footer_ftMain__351SA {
    padding: 24px 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2; }
    .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW {
      padding: 0;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      line-height: 1.43;
      color: #8b949a;
      margin: 0 0 0 16px; }
      .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_disclaimer__2i63o {
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        max-width: 698px; }
        .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_disclaimer__2i63o .Footer_logo__ca91g {
          padding: 0 8px; }
          .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_disclaimer__2i63o .Footer_logo__ca91g img {
            height: 42px; }
      .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw {
        display: inline; }
        .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul {
          list-style: none;
          padding-left: 24px;
          margin: 0; }
          .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul li {
            display: inline;
            margin: 0 14px 0 0; }
            .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul li a {
              text-decoration: none !important;
              color: #8b949a; }
  .Footer_footer__26U7T .Footer_ftBottom__3mF-4 {
    padding: 16px 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #fcfbfb; }
    .Footer_footer__26U7T .Footer_ftBottom__3mF-4 .Footer_ftMainItem__3E4CW {
      padding: 0;
      font-family: 'Karla', sans-serif;
      font-size: 14px;
      line-height: 1.43;
      color: #8b949a;
      margin: 0 0 0 16px; }
      .Footer_footer__26U7T .Footer_ftBottom__3mF-4 .Footer_ftMainItem__3E4CW.Footer_copyright__ugUfx {
        min-width: 240px;
        font-weight: 700; }

@media only screen and (min-width: 1040px) and (max-width: 1440px) {
  .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul {
    padding-left: 0;
    min-width: 300px; } }

@media only screen and (max-width: 1024px) {
  .Footer_footer__26U7T .Footer_ftBottom__3mF-4 {
    padding: 16px;
    margin: 0; }
    .Footer_footer__26U7T .Footer_ftBottom__3mF-4 .Footer_ftMainItem__3E4CW {
      margin: 0 16px 0 0; }
  .Footer_footer__26U7T .Footer_ftMain__351SA {
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 24px 16px; }
    .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW {
      text-align: center;
      margin: 0; }
      .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_disclaimer__2i63o {
        display: block;
        margin: 0 8px; }
        .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_disclaimer__2i63o .Footer_logo__ca91g {
          padding: 0 8px 8px 8px; }
      .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul {
        padding: 16px 0 0 0; }
        .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul li {
          margin: 0 12px 0 0; }
          .Footer_footer__26U7T .Footer_ftMain__351SA .Footer_ftMainItem__3E4CW.Footer_list__1cNOw ul li:last-child {
            margin: 0; } }

@media only screen and (max-width: 768px) {
  .Footer_footer__26U7T .Footer_ftBottom__3mF-4 {
    -webkit-flex-flow: column;
            flex-flow: column; }
    .Footer_footer__26U7T .Footer_ftBottom__3mF-4 .Footer_ftMainItem__3E4CW {
      padding: 0;
      margin: 0 0 12px 0; } }

.CurrentState_pageWrap__3GH9e {
  display: -webkit-flex;
  -webkit-flex-flow: column;
  display: flex;
  flex-flow: column;
  height: 100vh; }

.CurrentState_wrapper__3DTUy {
  padding: 64px 0;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex: 1 0 auto; }
  .CurrentState_wrapper__3DTUy h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
  .CurrentState_wrapper__3DTUy .CurrentState_subtitle__1t5AZ {
    text-align: center;
    font-size: 32px;
    color: #253139;
    line-height: 144%;
    margin-bottom: 60px; }
  .CurrentState_wrapper__3DTUy .CurrentState_btnDefault__3aFWW {
    background-color: #2092c1;
    color: #fff;
    font-weight: 700; }
  @media only screen and (max-width: 1196px) {
    .CurrentState_wrapper__3DTUy {
      padding: 62px 0; } }
  @media only screen and (max-width: 768px) {
    .CurrentState_wrapper__3DTUy {
      padding: 0 0 48px 0; }
      .CurrentState_wrapper__3DTUy p.CurrentState_subtitle__1t5AZ {
        margin-bottom: 20px; } }

.InfoBlock_box__DXpS2 {
  width: 100%;
  height: 200px;
  max-width: 340px;
  padding: 24px;
  margin: 8px auto;
  border: 2px #2092c1 solid;
  border-radius: 12px;
  cursor: pointer; }
  .InfoBlock_box__DXpS2 .InfoBlock_boxTitle__3T6x4 {
    color: #2092c1;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    cursor: pointer; }
  .InfoBlock_box__DXpS2 .InfoBlock_boxDescription__2xTJT {
    font-family: 'Karla', sans-serif;
    max-width: 228px;
    font-size: 16px;
    line-height: 1.5; }
  .InfoBlock_box__DXpS2.InfoBlock_active__3wQ29, .InfoBlock_box__DXpS2:hover, .InfoBlock_box__DXpS2 input[type=radio]:checked {
    background-color: #2092c1;
    box-shadow: 0 0 20px 1px #7d7d7d; }
    .InfoBlock_box__DXpS2.InfoBlock_active__3wQ29 label, .InfoBlock_box__DXpS2.InfoBlock_active__3wQ29 p, .InfoBlock_box__DXpS2.InfoBlock_active__3wQ29 h3, .InfoBlock_box__DXpS2:hover label, .InfoBlock_box__DXpS2:hover p, .InfoBlock_box__DXpS2:hover h3, .InfoBlock_box__DXpS2 input[type=radio]:checked label, .InfoBlock_box__DXpS2 input[type=radio]:checked p, .InfoBlock_box__DXpS2 input[type=radio]:checked h3 {
      color: #fff; }
  .InfoBlock_box__DXpS2 input[type=radio] {
    display: none; }

.InfoBlock_boxActive__GRkgY {
  background-color: #1f92c1;
  box-shadow: 0 0 20px 1px #7d7d7d; }
  .InfoBlock_boxActive__GRkgY .InfoBlock_boxTitle__3T6x4, .InfoBlock_boxActive__GRkgY .InfoBlock_boxDescription__2xTJT {
    color: #fff; }

.InfoBlock_box_alt__3DsU2 {
  border: 2px #20ca83 solid; }
  .InfoBlock_box_alt__3DsU2 .InfoBlock_boxTitle__3T6x4 {
    color: #20ca83;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    cursor: pointer; }
  .InfoBlock_box_alt__3DsU2 .InfoBlock_boxDescription__2xTJT {
    font-family: 'Karla', sans-serif;
    max-width: 228px;
    font-size: 16px;
    line-height: 1.5; }
  .InfoBlock_box_alt__3DsU2.InfoBlock_active__3wQ29, .InfoBlock_box_alt__3DsU2:hover, .InfoBlock_box_alt__3DsU2 input[type=radio]:checked {
    background-color: #20ca83;
    box-shadow: 0 0 20px 1px #7d7d7d; }
    .InfoBlock_box_alt__3DsU2.InfoBlock_active__3wQ29 label, .InfoBlock_box_alt__3DsU2.InfoBlock_active__3wQ29 p, .InfoBlock_box_alt__3DsU2.InfoBlock_active__3wQ29 h3, .InfoBlock_box_alt__3DsU2:hover label, .InfoBlock_box_alt__3DsU2:hover p, .InfoBlock_box_alt__3DsU2:hover h3, .InfoBlock_box_alt__3DsU2 input[type=radio]:checked label, .InfoBlock_box_alt__3DsU2 input[type=radio]:checked p, .InfoBlock_box_alt__3DsU2 input[type=radio]:checked h3 {
      color: #fff; }

@media only screen and (max-width: 767px) {
  .InfoBlock_box__DXpS2 {
    height: 150px; }
  .InfoBlock_boxTitle__3T6x4 {
    font-family: 'OpenSans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.1px;
    text-align: center; } }

.ThankYou_wrapper__3KYT5 {
  margin: 60px 0;
  min-height: 70vh; }
  .ThankYou_wrapper__3KYT5 .ThankYou_formContainer__z2vg3 {
    max-width: 700px;
    margin: 0 auto; }
  .ThankYou_wrapper__3KYT5 h1 {
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
    color: #253139;
    margin-bottom: 20px; }
  .ThankYou_wrapper__3KYT5 h3 {
    font-size: 1.2rem;
    margin-bottom: 24px;
    font-weight: 700; }
  .ThankYou_wrapper__3KYT5 p.ThankYou_subtitle__AF1Dz {
    text-align: center; }
  .ThankYou_wrapper__3KYT5 .ThankYou_result__9gtnG {
    padding: 20px 80px;
    border-radius: 4px;
    border: solid 1px #2092c1;
    background-color: rgba(12, 201, 255, 0.17);
    margin: 10px 0; }
    .ThankYou_wrapper__3KYT5 .ThankYou_result__9gtnG p {
      margin-bottom: 0; }
  .ThankYou_wrapper__3KYT5 label {
    font-size: 16px;
    line-height: 155%;
    margin-right: 16px; }
  .ThankYou_wrapper__3KYT5 input {
    background-color: #edeced;
    margin-right: 8px; }
  .ThankYou_wrapper__3KYT5 textarea {
    background-color: #edeced; }
  .ThankYou_wrapper__3KYT5 .ThankYou_btnDefault__1tqb_ {
    background-color: #2092c1;
    color: #fff;
    font-weight: 700;
    width: 100%; }
    .ThankYou_wrapper__3KYT5 .ThankYou_btnDefault__1tqb_:disabled {
      cursor: not-allowed; }
  .ThankYou_wrapper__3KYT5 .ThankYou_notesField__398Ug {
    height: 140px; }
  .ThankYou_wrapper__3KYT5 .ThankYou_alertMessage__3Li3R {
    text-align: center;
    color: #ff0034;
    font-weight: 700;
    margin-bottom: 32px; }

@media only screen and (max-width: 767px) {
  .ThankYou_wrapper__3KYT5 .ThankYou_result__9gtnG {
    padding: 16px;
    margin: 10px 0; } }

.Home_pageWrap__2nL5h {
  display: -webkit-flex;
  -webkit-flex-flow: column;
  display: flex;
  flex-flow: column;
  height: 100vh; }

.Home_main__3om9p {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-bottom: 40px;
  /*.title {
        font-size: 54px;
        line-height: 60px;
    }*/ }

.Home_content__2cul8 {
  display: -webkit-flex;
  -webkit-flex: 1 0 auto;
  display: flex;
  flex: 1 0 auto;
  -webkit-align-items: center;
          align-items: center; }
  .Home_content__2cul8 .Home_subtitle__1TK-V {
    font-size: 18px;
    margin-bottom: 60px; }

.Home_banner__U_jo7 {
  display: -webkit-flex;
  -webkit-flex: 0 1 auto;
  display: flex;
  flex: 0 1 auto;
  margin: 48px 0 0 0;
  padding: 3rem 0;
  color: #fff;
  background-color: #2092c1; }
  .Home_banner__U_jo7 p {
    color: #fff; }

.Home_newspaper__2oiKH .Home_newspaperTitle__2RGQb {
  margin-bottom: 48px; }
  .Home_newspaper__2oiKH .Home_newspaperTitle__2RGQb h2 {
    font-family: 'OpenSans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.5px;
    color: #253139; }

.Home_newspaper__2oiKH .Home_imageContainer__3h-b4 {
  display: -webkit-flex;
  -webkit-justify-content: center;
  display: flex;
  justify-content: center; }
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC {
    float: left;
    margin: 20px 32px; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC img {
      max-height: 56px;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%); }
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapper__1Bv3y {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px 0; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapper__1Bv3y img {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      max-height: 44px; }

.Home_explain_section__3XEYR {
  margin: 160px 0 0; }
  .Home_explain_section__3XEYR .Home_explain_title__35l29 {
    margin-bottom: 48px; }
    .Home_explain_section__3XEYR .Home_explain_title__35l29 h2 {
      font-family: 'OpenSans', sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.5px;
      color: #253139; }
  .Home_explain_section__3XEYR .Home_explain_inner__1CJ7F {
    padding: 32px 0; }
    .Home_explain_section__3XEYR .Home_explain_inner__1CJ7F h4 {
      font-family: 'OpenSans', sans-serif;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.1px;
      color: #2092c1; }
  .Home_explain_section__3XEYR p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #3e515e;
    margin: 0; }

.Home_main__3om9p {
  margin: 40px 0 0 0; }

@media only screen and (min-width: 768px) {
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC {
    text-align: center;
    margin: 20px 16px; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC img {
      max-height: 40px; } }

@media only screen and (min-width: 992px) {
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC {
    text-align: center;
    margin: 20px 28px; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC img {
      max-height: 48px; } }

@media only screen and (min-width: 1200px) {
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC {
    text-align: center;
    margin: 20px 36px; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC img {
      max-height: 56px; } }

@media only screen and (max-width: 767px) {
  .Home_main__3om9p {
    margin: 20px 0 0 0; }
    .Home_main__3om9p .Home_title__omcyC {
      font-size: 28px;
      line-height: 32px;
      color: #000; }
  .Home_content__2cul8 {
    padding: 10px 0 60px 0; }
    .Home_content__2cul8 .Home_subtitle__1TK-V {
      font-size: 16px;
      margin-bottom: 32px; }
  .Home_banner__U_jo7 {
    padding: 40px 0;
    margin: 20px 0 0 0; }
  .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 {
    -webkit-flex-direction: column;
    flex-direction: column; }
    .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC {
      float: none;
      margin: 12px 0;
      text-align: center; }
      .Home_newspaper__2oiKH .Home_imageContainer__3h-b4 .Home_imageWrapperFloat__PcOJC img {
        max-height: 56px; }
  .Home_explain_section__3XEYR {
    margin: 80px 0 0; }
    .Home_explain_section__3XEYR .Home_explain_title__35l29 h2 {
      font-size: 32px; }
    .Home_explain_section__3XEYR .Home_explain_inner__1CJ7F {
      padding: 24px 0; }
      .Home_explain_section__3XEYR .Home_explain_inner__1CJ7F h4 {
        font-size: 24px; } }

a {
  text-decoration: none !important;
  cursor: pointer; }
  a .UserCard_card__2A5Tj {
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    border: 2px solid transparent;
    box-shadow: 0 2px 10px 0 rgba(62, 81, 94, 0.35);
    padding: 32px;
    text-align: center;
    max-width: 480px;
    margin: 32px 0;
    min-height: 359px;
    /* margin: 0 auto;*/ }
    a .UserCard_card__2A5Tj img {
      max-width: 92px;
      min-height: 101px;
      margin-bottom: 24px; }
    a .UserCard_card__2A5Tj .UserCard_title__3BhB9 {
      font-family: 'OpenSans', sans-serif;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.4px;
      text-align: center;
      color: #2092c1; }
    a .UserCard_card__2A5Tj .UserCard_message__B2_kF {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #3e515e;
      padding: 8px 0 24px;
      margin: 0; }
    a .UserCard_card__2A5Tj .UserCard_cta__2ANKV {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      color: #0cc9ff;
      text-decoration: underline !important; }
    a .UserCard_card__2A5Tj:hover {
      box-sizing: border-box;
      border: 2px solid #2092c1;
      /*transition: ease-in 300ms;*/ }

@media only screen and (max-width: 767px) {
  a .UserCard_card__2A5Tj {
    margin: 16px auto;
    min-height: unset; }
    a .UserCard_card__2A5Tj img {
      max-width: 64px;
      min-height: 71px;
      margin: 0; }
    a .UserCard_card__2A5Tj .UserCard_title__3BhB9 {
      font-size: 24px; }
    a .UserCard_card__2A5Tj .UserCard_message__B2_kF {
      padding: 16px 0 12px;
      text-align: left; }
    a .UserCard_card__2A5Tj .UserCard_cta__2ANKV {
      text-align: left;
      display: block; } }

.ComeBack_title__1JR33 {
  font-family: 'OpenSans', sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 155%;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff; }

.ComeBack_subtitle__24kqI {
  font-family: 'OpenSans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: #fff; }

.ComeBack_notes__2Qw8J {
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.39);
  padding: 20px; }

.ComeBack_fullPage__lscgv {
  height: 90vh;
  background-color: #2092c1;
  color: #fff !important; }

.ComeBack_fullHeight__3UzCT {
  height: 100%; }

.ComeBack_logoBig__3k0hA img {
  height: 68px; }

.ComeBack_content__kpGmq {
  margin: 100px 0 70px 0; }

@media only screen and (max-width: 767px) {
  .ComeBack_title__1JR33 {
    font-size: 20px;
    line-height: 1.33; }
  .ComeBack_subtitle__24kqI {
    font-size: 14px;
    line-height: 1.5; } }

.Team_pageWrap__1D9PD {
  display: -webkit-flex;
  -webkit-flex-flow: column;
  display: flex;
  flex-flow: column;
  height: 100vh; }

.Team_main__2VRDY {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 40px 0 40px 0; }
  .Team_main__2VRDY h2 {
    color: #2092c1;
    font-family: 'OpenSans', sans-serif;
    font-size: 32px;
    font-weight: 700; }

.Team_team__31ENP {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 0; }
  .Team_team__31ENP .Team_title__1h1v7 {
    margin-bottom: 8px; }
    .Team_team__31ENP .Team_title__1h1v7 a {
      text-decoration: none; }
  .Team_team__31ENP .Team_subtitle__3keLG {
    text-align: center; }
  .Team_team__31ENP .Team_mainLogo__sAi4B svg {
    width: 100%;
    height: auto;
    margin: 20px 0; }

.Team_advisors__2WxAv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 0; }

.Team_contributors__1pxnT {
  padding: 40px 0; }
  .Team_contributors__1pxnT .Team_subtitle__3keLG {
    color: #3e515e; }
  .Team_contributors__1pxnT .Team_contributorsWrapper__2Gu77 img {
    height: auto;
    max-width: 100%; }

.Team_teamItemWrapper__3E5mF {
  text-align: center;
  padding: 16px; }
  .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH {
    margin-bottom: 16px;
    padding: 40px; }
    .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH .Team_photoWrapper__1eVIA {
      margin: 0 auto; }
      .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH .Team_photoWrapper__1eVIA .Team_photoBg__6KSGW {
        border-radius: 50%; }
        .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH .Team_photoWrapper__1eVIA .Team_photoBg__6KSGW img {
          width: 100%;
          border-radius: 50%; }
  .Team_teamItemWrapper__3E5mF .Team_description__3P_-u .Team_role__3aAQs {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase; }
  .Team_teamItemWrapper__3E5mF .Team_description__3P_-u .Team_title__1h1v7 {
    margin-bottom: 8px;
    font-size: 20px; }

.Team_enhanced__CbxmH {
  color: #2092c1;
  font-weight: 700; }

@media only screen and (max-width: 1199.98px) {
  .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH {
    padding: 16px; } }

@media only screen and (max-width: 767.98px) {
  .Team_team__31ENP .Team_mainLogo__sAi4B svg {
    padding: 16px 32px; }
  .Team_teamItemWrapper__3E5mF {
    padding: 16px 0; }
    .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH {
      padding: 16px 128px; }
  .Team_main__2VRDY {
    margin: 20px 0 0 0; }
  .Team_content__20tRs {
    padding: 10px 0 60px 0; }
  .Team_title__1h1v7 {
    font-size: 28px;
    line-height: 32px;
    color: #000; } }

@media only screen and (max-width: 575.98px) {
  .Team_teamItemWrapper__3E5mF .Team_teamItemPhoto__3BFbH {
    padding: 16px 80px; } }

.StaticPages_pageWrap__1_HR9 {
  display: -webkit-flex;
  -webkit-flex-flow: column;
  display: flex;
  flex-flow: column;
  height: 100vh; }

.StaticPages_terms__3KQM3 p, .StaticPages_terms__3KQM3 ul, .StaticPages_terms__3KQM3 ol {
  font-size: 12px; }

.StaticPages_main__1RFZp {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 40px 0 40px 0; }
  .StaticPages_main__1RFZp h2 {
    color: #2092c1;
    font-family: 'OpenSans', sans-serif;
    font-size: 32px;
    font-weight: 700; }

.StaticPages_team__xSfWW {
  display: -webkit-flex;
  /*-webkit-flex: 1 0 auto;*/
  display: flex;
  /*flex: 1 0 auto;*/
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 0 40px 0; }
  .StaticPages_team__xSfWW .StaticPages_title__3TE3O {
    margin-bottom: 8px; }
    .StaticPages_team__xSfWW .StaticPages_title__3TE3O a {
      text-decoration: none; }
  .StaticPages_team__xSfWW .StaticPages_subtitle__2z5jN {
    text-align: center; }
  .StaticPages_team__xSfWW .StaticPages_mainLogo__3julO svg {
    width: 100%;
    height: auto;
    margin: 20px 0; }

.StaticPages_advisors__1yOGE {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 0 40px 0; }

.StaticPages_contributors__J5XXk .StaticPages_subtitle__2z5jN {
  color: #2092c1; }

.StaticPages_contributors__J5XXk .StaticPages_contributorsWrapper__yiBXm img {
  height: auto;
  max-width: 100%; }

.StaticPages_teamItemWrapper__Rr8xm {
  text-align: center;
  padding: 16px;
  margin: 16px 0; }
  .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S {
    margin-bottom: 16px;
    padding: 40px; }
    .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S .StaticPages_photoWrapper__1W_rF {
      margin: 0 auto; }
      .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S .StaticPages_photoWrapper__1W_rF .StaticPages_photoBg__rpfkd {
        /*border: 3px solid #2092c1;*/
        /*-webkit-box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);
                -moz-box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);
                box-shadow: 4px 4px 12px 2px rgba(190, 203, 225, 0.9);*/
        border-radius: 50%;
        /*padding-top: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('https://images.squarespace-cdn.com/content/v1/5aa81fb4506fbe5eef56d916/1554713557310-4YQ1IENO8YR2NHN82NOH/ke17ZwdGBToddI8pDm48kOyctPanBqSdf7WQMpY1FsRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyD4IQ_uEhoqbBUjTJFcqKvko9JlUzuVmtjr1UPhOA5qkTLSJODyitRxw8OQt1oetw/placeholder.png');
            */ }
        .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S .StaticPages_photoWrapper__1W_rF .StaticPages_photoBg__rpfkd img {
          max-width: 100%;
          border-radius: 50%; }
  .StaticPages_teamItemWrapper__Rr8xm .StaticPages_description__1olYs .StaticPages_role__FRiBG {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase; }
  .StaticPages_teamItemWrapper__Rr8xm .StaticPages_description__1olYs .StaticPages_title__3TE3O {
    font-size: 20px; }

.StaticPages_enhanced__5L8bG {
  color: #2092c1;
  font-weight: 700; }

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S {
    padding: 16px; } }

@media only screen and (max-width: 767px) {
  .StaticPages_teamItemWrapper__Rr8xm {
    padding: 16px 0; }
    .StaticPages_teamItemWrapper__Rr8xm .StaticPages_teamItemPhoto__3ym9S {
      padding: 16px 40px; }
  .StaticPages_main__1RFZp {
    margin: 20px 0 0 0; }
  .StaticPages_content__1Tw7c {
    padding: 10px 0 60px 0; }
  .StaticPages_title__3TE3O {
    font-size: 28px;
    line-height: 32px;
    color: #000; } }

.DoctorApplication_wrapper__3PXY5 {
  margin: 60px 0;
  min-height: 70vh; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_offer__29JOG, .DoctorApplication_wrapper__3PXY5 .DoctorApplication_signup__2ew-W {
    padding: 48px 0; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_formContainer__jvLOp {
    max-width: 1024px;
    padding: 0 24px;
    margin: 0 auto; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_formContainer__jvLOp .DoctorApplication_doctorSection__3sPgm {
      margin-bottom: 56px; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_formContainer__jvLOp .DoctorApplication_featuresSection__D9HVd .DoctorApplication_featuresTitle__1b158 {
      margin-bottom: 64px;
      font-family: 'OpenSans', sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
      color: #2092c1; }
  .DoctorApplication_wrapper__3PXY5 h1 {
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
    color: #253139;
    margin-bottom: 20px; }
  .DoctorApplication_wrapper__3PXY5 h3 {
    font-size: 1.2rem;
    margin-bottom: 24px;
    font-weight: 700; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_subtitle__wYF3E {
    text-align: center;
    font-size: 14px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_spamAlert___pG8W {
    text-align: center;
    font-size: 14px;
    color: #ff0034; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_requested__37_Z6 {
    text-align: left;
    font-size: 14px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_result__1119c {
    padding: 20px 80px;
    border-radius: 4px;
    border: solid 1px #2092c1;
    background-color: rgba(12, 201, 255, 0.17);
    margin: 40px 0; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_result__1119c p {
      margin-bottom: 0; }
  .DoctorApplication_wrapper__3PXY5 label {
    font-size: 16px;
    line-height: 155%;
    margin-right: 16px; }
  .DoctorApplication_wrapper__3PXY5 input, .DoctorApplication_wrapper__3PXY5 select {
    background-color: #edeced;
    margin-right: 8px; }
  .DoctorApplication_wrapper__3PXY5 textarea {
    background-color: #edeced; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_btnDefault__12QmM {
    background-color: #2092c1;
    color: #fff;
    font-weight: 700;
    width: 100%; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_btnDefault__12QmM:disabled {
      cursor: not-allowed; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_notesField__3D3j5 {
    height: 140px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_alertMessage__WBZCJ {
    text-align: center;
    color: #ff0034;
    font-weight: 700;
    margin-bottom: 32px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_icon__29sE0 {
    float: left;
    margin: 28px; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_icon__29sE0 svg {
      max-width: 94px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_text__33YYZ {
    overflow: hidden; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_text__33YYZ h4 {
      font-family: 'OpenSans', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.56;
      color: #3e515e; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_text__33YYZ p {
      font-family: 'Karla', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.1px;
      color: #3e515e; }

@media only screen and (max-width: 992px) {
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_formContainer__jvLOp .DoctorApplication_featuresSection__D9HVd .DoctorApplication_featuresTitle__1b158 {
    margin-bottom: 48px; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_result__1119c {
    padding: 16px;
    margin: 20px 0; }
  .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq {
    text-align: center; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_icon__29sE0 {
      width: 100%;
      margin: 28px 0;
      float: none; }
    .DoctorApplication_wrapper__3PXY5 .DoctorApplication_iconbox__39gJq .DoctorApplication_text__33YYZ {
      overflow: visible; } }

.HealedPatient_pageWrap__38zV0 {
  display: -webkit-flex;
  -webkit-flex-flow: column;
  display: flex;
  flex-flow: column;
  height: 100vh; }

.HealedPatient_wrapper__VK9E- {
  padding: 80px 0;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex: 1 0 auto;
  background-color: #2092c1; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_logoBig__2htgb img {
    height: 68px; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_title__1oLyG {
    font-family: 'OpenSans', sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #ffffff; }
  .HealedPatient_wrapper__VK9E- h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_subtitle__s-zEq {
    text-align: center;
    font-size: 24px;
    color: #fff;
    line-height: 1.2;
    margin-bottom: 60px; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_communication__1X7bJ .HealedPatient_dangerIcon__3otlu {
    padding: 16px; }
    .HealedPatient_wrapper__VK9E- .HealedPatient_communication__1X7bJ .HealedPatient_dangerIcon__3otlu svg {
      fill: #FDD007; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_communication__1X7bJ .HealedPatient_communicationTitle__3jqC6 {
    margin-bottom: 24px;
    font-size: 24px;
    color: #fff; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_communication__1X7bJ .HealedPatient_communicationParagraph__1XBkY {
    font-family: 'OpenSans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: #ffffff; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 {
    padding: 24px;
    border-radius: 4px;
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.39); }
    .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 p:last-child {
      margin-bottom: 0; }
    .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 .HealedPatient_copyArea__1PIcP .HealedPatient_copyGroup__3NRgJ {
      display: -webkit-flex;
      -webkit-justify-content: center;
      display: flex;
      justify-content: center; }
      .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 .HealedPatient_copyArea__1PIcP .HealedPatient_copyGroup__3NRgJ .HealedPatient_copyInput__3oiD9 {
        max-width: 180px;
        border-radius: 4px;
        background-blend-mode: multiply;
        background-image: -webkit-linear-gradient(top, rgba(9, 19, 33, 0.06), rgba(9, 19, 33, 0.06));
        background-image: linear-gradient(to bottom, rgba(9, 19, 33, 0.06), rgba(9, 19, 33, 0.06));
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
    .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 .HealedPatient_copyArea__1PIcP .HealedPatient_copyMessage__1Mzwa {
      padding: 8px; }
    .HealedPatient_wrapper__VK9E- .HealedPatient_boxMessage__1WNm7 .HealedPatient_btnDefault__2-wGu {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
  .HealedPatient_wrapper__VK9E- .HealedPatient_btnDefault__2-wGu {
    background-color: #2092c1;
    color: #fff;
    font-weight: 700; }
  @media only screen and (max-width: 1196px) {
    .HealedPatient_wrapper__VK9E- {
      padding: 62px 0; } }
  @media only screen and (max-width: 768px) {
    .HealedPatient_wrapper__VK9E- {
      padding: 48px 0; }
      .HealedPatient_wrapper__VK9E- p.HealedPatient_subtitle__s-zEq {
        margin-bottom: 20px; } }

