
form{
    .formCustomCheckmark {
        margin-bottom: 8px;
        input[type=checkbox][disabled]+label{
            cursor: not-allowed;
            a{
                cursor: not-allowed;
                color: #8395a6;
            }
            &::before{
                border-color: #1f92c157;
                opacity:0.6;
            }
        }
    }

    .formCustomCheckmark input[type=checkbox] {
        display: none;
    }

    .formCustomCheckmark {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
        position: relative;
    }
    .formCustomCheckmark label {
        cursor: pointer;
        position: relative;
        padding: 0 0 0 36px;
    }
    .formCustomCheckmark label:before {
        position: absolute;
        top:0;
        left: 0;
        display: inline-block;
        width: 26px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid #1f92c1;
        content: "\00a0";
        text-align: center;
        background: #eee;
    }
    .formCustomCheckmark label:hover::before{
        background: #aaa;
    }
    /* On check */
    .formCustomCheckmark input:checked ~ label:before {
        content: "\2713";
        background: #1f92c1;
        color: #fff;
    }

    /* Form Checkbox */
    .formCustomCheckbox {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 20px;
    }

    .formCustomCheckbox input[type=radio] {
        display: none;
    }

    .formCustomCheckbox label {
        cursor: pointer;
        margin-left: 16px;
        padding: 8px;
        min-width: 141px;
        border-radius: 4px;
        border: solid 1px #2092c1;
    }
    .formCustomCheckbox label:before {
        display: inline-block;
        width: 26px;
        margin-right: 12px;
        border-radius: 20px;
        border: 1px solid #2092c1;
        content: "\00a0";
        text-align: center;
        background: #eee;
    }
    .formCustomCheckbox label:hover::before{
        content: "\2713";
        background: #2092c1;
        color: #fff;
    }
    .formCustomCheckbox label:hover{
        border: solid 1px #2092c1;
    }

    /* On check */
    .formCustomCheckbox input:checked ~ label:before {
        content: "\2713";
        background: #2092c1;
        color: #fff;
    }

    .formCustomCheckbox input:checked ~ label {
        border: solid 1px #2092c1;
        background-color: rgba(12, 201, 255, 0.26);

    }


    /* Placeholder */

    ::-webkit-input-placeholder { /* Edge */
        font-size: 12px;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 12px;
    }

    ::placeholder {
        font-size: 12px;
    }

    .invalid-input {
        color: #ff0034;
        font-size: 12px;
    }

}






