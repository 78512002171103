.header{
    width: 100%;
    background-color: #fff;
    padding: 16px 32px;
    display:-webkit-flex;
    -webkit-flex: 0 1 auto;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    //justify-content: center;
    /*justify-content: space-around;
    flex-wrap: wrap;*/
    -webkit-justify-content: space-between;
    justify-content: space-between;
    //flex-wrap: wrap;

    .logo{
        img{
            height: 36px;
        }
    }

    .mainNav {
        .mainNavList {
            //margin: 1em 0 .5em;
            padding: 0;
            margin: 0;
            text-align: center;
            li {
                display: inline;
                list-style: none;
                a {
                    display: inline-block;
                    //padding: .5em 1.5em;
                    padding: 0;
                    color: #2092c1;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase ;
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .header{
        -webkit-justify-content: center;
        justify-content: center;
        padding: 16px;
        .mainNav {
            display: none;
        }
    }
}
