@media only screen and (max-width: 767px) {

    h2 {
        font-size: 28px;
        line-height: 32px;
    }

    /* Todo: Temporary - Waiting for design */

    .survey-answer {
        position: relative;
        padding: 16px 16px 16px 48px;
        border: 1px solid #2092c1;
        border-radius: 4px;
        min-width: initial;
    }
    .customCheckmark label:before,
    .customCheckbox label:before {
        position: absolute;
        top: 14px;
        left: 14px;
    }

    .btn-default {
        width: 100% !important;
    }
    .btns-container {
        margin: 16px 0;
    }

    .btns-container .next {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    .btns-container .back{
        grid-column: 1 / -1;
        text-align: center;
    }

    .survey-section{
        padding: 40px 0;
    }

    .customCheckbox label:hover::before{
        content: "\00a0";
        background: #eee;
    }
    .customCheckbox label:hover{
        background: none;
    }
}
