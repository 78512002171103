
h1, h2, h3, h4, h5, h6 {
    font-family: 'Karla', sans-serif;
    color: #253139;
}

h1{
    font-family: 'OpenSans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -1px;
    text-align: center;
    color: #253139;
}
h2 {
    font-size: 40px;
    line-height: 48px;
}

h3 {
    font-family: 'OpenSans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.19;
    letter-spacing: -0.4px;
    /*text-align: center;*/
}

.label {
    font-family: 'OpenSans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: #ffffff;
}



p{
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    line-height: 1.56;
    color: #3e515e;
    font-weight: 400;
}

a {
    text-decoration: underline !important;
    color: #212529;
    &:hover {
        color:#212529;
    }
}
