.btn-default {
    background-color: #2092c1;
    color: #fff;
    font-weight: 700;
    width: 120px;
    font-family: 'OpenSans', sans-serif;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.1px;
    text-align: center;
    text-decoration: none !important;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(62, 81, 94, 0.32);
    &:hover{
        color: #fff;
    }
    &:hover:not(:disabled) {
        background-color: #2083b1;
        color:#fff;
        transition: ease-in 0.2s  ;
    }
}

.btns-container{
    margin: 32px 0;
    display: grid;
    grid-gap: 16px;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.btns-container .back{
    text-align: left;
    font-size: 16px;
    font-family: 'Karla', sans-serif;
    color: #3e515e;
}

.btns-container .next{
    text-align: right;
    .btn{
        width: 100px;
    }
}
