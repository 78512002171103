* {
    font-family: 'Karla', sans-serif;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.header{
    width: 100%;
    background-color: #fff;
    padding: 32px 8px;
    display:-webkit-flex;
    -webkit-flex: 0 1 auto;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
}

/*.footer{
    background-color: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    p{
        margin:0;
    }
}*/

.survey-section{
    padding: 60px 0 60px 0;
}

.survey-container{
    max-width: 860px;
}

.survey-answers {
    h3{
        font-size: 32px;
        color: #3e515e;
        font-family: 'Karla', sans-serif;
        text-align: left;
        margin-bottom: 16px;
    }
}

.survey-answer {
    label {
        opacity: 0.96;
        font-family: 'Karla', sans-serif;
        font-size: 18px;
        line-height: 1.56;
        color: #253139;
    }
    padding: 8px 16px;
    border: 1px solid #2092c1cc;
    border-radius: 4px;
    /*min-width: 500px;*/
    width: 100%;
}

.small-text {
    opacity: 0.92;
    font-size: 14px;
    line-height: 1.43;
    color: #2092c1;
    font-weight: 400;
}

/* Survey Checkmark */
.customCheckmark {
    margin-bottom: 8px;
}

.customCheckmark input[type=checkbox] {
    display: none;
}

.customCheckmark {
    font-size: 16px;
    line-height: 20px;
}
.customCheckmark label {
    cursor: pointer;
}

.customCheckmark {
    label:before {
        display: inline-block;
        width: 22px;
        margin-right: 12px;
        border-radius: 20px;
        border: 1px solid #2092c1cc;
        content: "\00a0";
        text-align: center;
        background: #eee;
    }
    &.multipleCheck{
        label:before {
            border-radius: 4px;
        }
    }
}

.customCheckmark label:hover::before{
    /*content: "\2713";
    background: #2092c1;
    color: #fff;*/
}
@media only screen and (min-width: 768px){
    .customCheckmark label:hover{
        /*border: solid 1px #2092c1;*/
        background-color: rgba(12, 201, 255, 0.26);
    }
}


/* On check */
.customCheckmark input:checked ~ label:before {
    content: "\2713";
    background: #2092c1;
    color: #fff;
}

.customCheckmark input:checked ~ label {
    border: solid 1px #2092c1;
    background-color: rgba(12, 201, 255, 0.26);
}

/* Survey Checkbox */
.customCheckbox {
    margin-bottom: 8px;
}

.customCheckbox input[type=radio] {
    display: none;
}

.customCheckbox {
    font-size: 16px;
    line-height: 20px;
}
.customCheckbox label {
    cursor: pointer;
}
.customCheckbox label:before {
    display: inline-block;
    width: 22px;
    margin-right: 12px;
    border-radius: 20px;
    border: 1px solid #2092c1;
    content: "\00a0";
    text-align: center;
    background: #eee;
}
.customCheckbox label:hover::before{
    /*content: "\2713";
    background: #2092c1;
    color: #fff;*/
}
.customCheckbox label:hover{
    /*border: solid 1px #2092c1;*/
    background-color: rgba(12, 201, 255, 0.26);
}

/* On check */
.customCheckbox input:checked ~ label:before {
    content: "\2713";
    background: #2092c1;
    color: #fff;
}

.customCheckbox input:checked ~ label {
    border: solid 1px #2092c1;
    background-color: rgba(12, 201, 255, 0.26);
}


.statics{
    ul,ol{
        li{
            font-weight: 400;
            font-size: 18px;
            color: #3e515e;
        }
    }
}
