.title{
    font-family: 'OpenSans', sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 155%;
    letter-spacing: -0.1px;
    text-align: center;
    color: #ffffff;
}

.subtitle{
    font-family: 'OpenSans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;
}

.notes {
    border-radius: 4px;
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.39);
    padding: 20px;
}

.fullPage {
    height: 90vh;
    background-color: #2092c1;
    color: #fff !important;
}

.fullHeight {
    height: 100%;
}

.logoBig {
    img{
        height: 68px;
    }
}

.content {
    margin: 100px 0 70px 0;
}

@media only screen and (max-width: 767px) {
    .title{
        font-size: 20px;
        line-height: 1.33;
    }
    .subtitle{
        font-size: 14px;
        line-height: 1.5;
    }
}
